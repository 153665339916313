import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigationBar from "../main/NavigationBar";
import dashboard from "./dashboard.jpg";
import myImage from "./data.png.jpg";
import insights from "./insights.jpg";

const CommoditySettings = () => {
  const [supportedCommodities, setSupportedCommodities] = useState([]);
  const [activatedCommodities, setActivatedCommodities] = useState([]);
  const [message, setMessage] = useState("");
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [showCashewNutDetails, setShowCashewNutDetails] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // State to track edit mode
  const [lossThresholds, setLossThresholds] = useState({
    boilingSteaming: 0.0,
    grading: 0.0,
    cutting: 0.0,
    primaryShelling: 0.0,
    secondaryShelling: 0.0,
    bormaDrying: 0.0,
    cooling: 0.0,
    peeling: 0.0,
    sweating: 0.0,
    sorting: 0.0,
    packaging: 0.0,
  });
  const [isCashewNutActivated, setIsCashewNutActivated] = useState(false);
  const navigate = useNavigate();

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const getSleekId = () => {
    return localStorage.getItem("sleekId");
  };

  useEffect(() => {
    const fetchCommodities = async () => {
      try {
        const token = getToken();
        const sleekId = getSleekId();

        if (!token || !sleekId) {
          setMessage("Sleek ID or token not found. Please log in again.");
          return;
        }

        const [supportedResponse, activatedResponse] = await Promise.all([
          fetch(
            `${process.env.REACT_APP_API_URL}/api/commodities/support-commodities`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
          fetch(
            `${process.env.REACT_APP_API_URL}/api/commodities/activated-commodities`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        ]);

        if (!supportedResponse.ok || !activatedResponse.ok) {
          throw new Error("Failed to fetch commodities.");
        }

        const supportedData = await supportedResponse.json();
        const activatedData = await activatedResponse.json();

        setSupportedCommodities(supportedData);
        setActivatedCommodities(activatedData);

        setIsCashewNutActivated(activatedData.includes("Cashew Nut"));
      } catch (error) {
        console.error("Error fetching commodities:", error);
        setMessage("Failed to fetch commodities.");
      }
    };

    const fetchLossThresholds = async () => {
      try {
        const token = getToken();
        const sleekId = getSleekId();

        if (!token || !sleekId) {
          setMessage("Sleek ID or token not found. Please log in again.");
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/${sleekId}/commodities/process-stages`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch loss thresholds.");
        }

        const data = await response.json();
        setLossThresholds(data);
      } catch (error) {
        console.error("Error fetching loss thresholds:", error);
        setMessage("Failed to fetch loss thresholds.");
      }
    };

    fetchCommodities();
    fetchLossThresholds();
  }, []);

  const handleSettingsToggle = () => {
    setShowSettingsMenu((prev) => !prev);
  };

  const handleProfile = () => {
    setShowSettingsMenu(true);
    navigate("/dashboard/profile");
  };

  const handleCommodities = () => {
    setShowSettingsMenu(true);
    navigate("/dashboard/commodity-settings");
  };

  const handleHistory = () => {
    setShowSettingsMenu(true);
    navigate("/dashboard/history");
  };

  const handleCashewNutClick = () => {
    setShowCashewNutDetails(true);
  };

  const handleLossThresholdChange = (process, value) => {
    const parsedValue = parseFloat(value);
    setLossThresholds((prev) => ({ ...prev, [process]: parsedValue }));
  };

  const handleSaveThresholds = async () => {
    try {
      const token = getToken();
      const sleekId = getSleekId();

      if (!token || !sleekId) {
        setMessage("Sleek ID or token not found. Please log in again.");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/${sleekId}/commodities/process-stages/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(lossThresholds),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update loss thresholds.");
      }

      // Exit edit mode on successful save
      setIsEditMode(false);
    } catch (error) {
      console.error("Error updating loss thresholds:", error);
      setMessage("Failed to update loss thresholds.");
    }
  };

  const handleResetLossThresholds = async () => {
    try {
      const token = getToken();
      const sleekId = getSleekId();

      if (!token || !sleekId) {
        setMessage("Sleek ID or token not found. Please log in again.");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/${sleekId}/commodities/process-stages/reset`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to reset loss thresholds.");
      }

      const data = await response.json();
      setLossThresholds(data);
    } catch (error) {
      console.error("Error resetting loss thresholds:", error);
      setMessage("Failed to reset loss thresholds.");
    }
  };

  const handleActivateToggle = () => {
    if (activatedCommodities.includes("Cashew Nut")) {
      setActivatedCommodities((prev) =>
        prev.filter((commodity) => commodity !== "Cashew Nut")
      );
    } else {
      setActivatedCommodities((prev) => [...prev, "Cashew Nut"]);
    }
  };

  const disableScrollOnNumberInput = (e) => {
    e.preventDefault();
  };

  return (
    <div className="flex ml-36">
      <div className="flex flex-col flex-1">
        <div className="flex flex-1">
          <div className="flex-1 gap-8 flex flex-col overflow-y-auto">
            {showCashewNutDetails ? (
              <div className="flex flex-1">
                <div className="flex-1 bg-white p-6 rounded">
                  <h1 className="text-2xl font-bold mb-4">
                    Manage Cashew Nut Processing
                  </h1>
                  <p className="mb-6">
                    Manage the loss thresholds and activation status for Cashew
                    Nut processing.
                  </p>

                  {/* Boiling/Steaming Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Boiling/Steaming</h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.boilingSteaming}
                        onChange={(e) =>
                          handleLossThresholdChange(
                            "boilingSteaming",
                            e.target.value
                          )
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode} // Disabled in non-edit mode
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Grading Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Grading</h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.grading}
                        onChange={(e) =>
                          handleLossThresholdChange("grading", e.target.value)
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Cutting Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Cutting</h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.cutting}
                        onChange={(e) =>
                          handleLossThresholdChange("cutting", e.target.value)
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Primary Shelling Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Primary Shelling</h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.primaryShelling}
                        onChange={(e) =>
                          handleLossThresholdChange(
                            "primaryShelling",
                            e.target.value
                          )
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Secondary Shelling Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">
                      Secondary Shelling
                    </h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.secondaryShelling}
                        onChange={(e) =>
                          handleLossThresholdChange(
                            "secondaryShelling",
                            e.target.value
                          )
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Borma Drying Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Borma Drying</h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.bormaDrying}
                        onChange={(e) =>
                          handleLossThresholdChange(
                            "bormaDrying",
                            e.target.value
                          )
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Cooling Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Cooling</h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.cooling}
                        onChange={(e) =>
                          handleLossThresholdChange("cooling", e.target.value)
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Peeling Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Peeling</h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.peeling}
                        onChange={(e) =>
                          handleLossThresholdChange("peeling", e.target.value)
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Sweating Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Sweating</h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.sweating}
                        onChange={(e) =>
                          handleLossThresholdChange("sweating", e.target.value)
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Sorting Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Sorting</h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.sorting}
                        onChange={(e) =>
                          handleLossThresholdChange("sorting", e.target.value)
                        }
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Packaging Section */}
                  <div className="mb-6">
                    <h2 className="text-xl font-semibold">Packaging</h2>
                    <div className="flex items-center mb-4">
                      <span className="mr-2">Loss Threshold</span>
                      <input
                        type="number"
                        value={lossThresholds.packaging}
                        onChange={(e) =>
                          handleLossThresholdChange("packaging", e.target.value)
                        }
                        onWheel={disableScrollOnNumberInput}
                        className="w-16 p-1 bg-gray-200 text-gray-900 rounded"
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">%</span>
                    </div>
                  </div>

                  {/* Edit/Save Button */}
                  <div className="flex space-x-4">
                    <button
                      onClick={() =>
                        isEditMode
                          ? handleSaveThresholds()
                          : setIsEditMode(true)
                      }
                      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-400"
                    >
                      {isEditMode ? "Save" : "Edit"}
                    </button>
                    <button
                      onClick={handleResetLossThresholds}
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-400"
                      disabled={isEditMode} // Disable reset if in edit mode
                    >
                      Reset Loss Thresholds
                    </button>
                    <button
                      onClick={handleActivateToggle}
                      className={`px-4 py-2 text-white rounded ${
                        activatedCommodities.includes("Cashew Nut")
                          ? "bg-red-500 hover:bg-red-400"
                          : "bg-green-500 hover:bg-green-400"
                      }`}
                      disabled={isEditMode} // Disable activation toggle if in edit mode
                    >
                      {activatedCommodities.includes("Cashew Nut")
                        ? "Deactivate Cashew Nut Processing"
                        : "Activate Cashew Nut Processing"}
                    </button>
                  </div>

                  {/* NavigationBar Component */}
                  <div>
                    <NavigationBar
                      activatedCommodities={activatedCommodities}
                    />
                  </div>
                </div>

                {/* Right Sidebar for Commodities */}
                <div className="w-80 bg-gray-100 p-6 rounded">
                  <h2 className="text-2xl font-bold mb-4">
                    Supported Commodities
                  </h2>
                  <ul className="mb-4">
                    {supportedCommodities.length > 0 ? (
                      supportedCommodities.map((commodity, index) => (
                        <li key={index} className="text-gray-700">
                          {commodity}
                        </li>
                      ))
                    ) : (
                      <li className="text-gray-700">Cashew Nut</li>
                    )}
                  </ul>
                  <h2 className="text-2xl font-bold mb-4">
                    Activated Commodities
                  </h2>
                  <ul>
                    {activatedCommodities.length > 0 ? (
                      activatedCommodities.map((commodity, index) => (
                        <li key={index} className="text-gray-700">
                          {commodity}
                        </li>
                      ))
                    ) : (
                      <p className="text-gray-500">None</p>
                    )}
                  </ul>
                </div>
              </div>
            ) : (
              <div className="flex flex-1">
                <div className="flex-1 bg-white p-6 rounded">
                  <h1 className="text-2xl font-bold mb-4">
                    Manage Commodity Processing
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing
                    elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua.
                  </p>
                  <div>
                    <div className="mb-6 mt-6">
                      <div className="flex items-center">
                        <img
                          src={myImage}
                          alt="Profile"
                          className="w-24 h-24 mr-4"
                        />
                        <div>
                          <h2 className="text-lg font-semibold">Data</h2>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor Incididunt ut labore et
                            dolore magna aliqua, Lorem ipsum dolor sit amet,
                            consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Lorem
                            ipsum dolor sit amet, consectetur adipisicing elit.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="flex items-center">
                        <img
                          src={dashboard}
                          alt="dashboard"
                          className="w-24 h-24 mr-4"
                        />
                        <div>
                          <h2 className="text-lg font-semibold">Dashboard</h2>
                          <p>
                            Pd tempor incididunt ut labore et dolore magna
                            aliqua. Lorem ipsum dolor sit amet, consectetur
                            adipisicing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Lorem ipsum dolor
                            sit amet, consectetur adipisicing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Lorem ipsum dolor sit amet, cconsecutor
                            adipisicing elit, sed to eiusmod tempor
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="flex items-center ml-3">
                        <img
                          src={insights}
                          alt="insights"
                          className="w-24 h-24 mr-9"
                        />
                        <div>
                          <h2 className="text-lg font-semibold">Insights</h2>
                          <p>
                            Adipisicing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Lorem ipsum dolor
                            sit amet, consectetur adipisicing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna
                            aliqua, Lorem ipsum dolor sit amet, consectetur
                            adipisicing elit, sed do eiusmod tempor incididunt
                            ut labore
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-80 bg-gray-100 h-full">
                  <h2 className="text-2xl font-bold mb-4">
                    Supported Commodities
                  </h2>
                  <ul className="mb-4">
                    {supportedCommodities.length > 0 ? (
                      supportedCommodities.map((commodity, index) => (
                        <li
                          key={index}
                          onClick={handleCashewNutClick}
                          className="text-gray-700 cursor-pointer hover:bg-gray-200 p-2 rounded"
                        >
                          {commodity}
                        </li>
                      ))
                    ) : (
                      <li
                        onClick={handleCashewNutClick}
                        className="text-gray-700 cursor-pointer hover:bg-gray-200 p-2 rounded"
                      >
                        Cashew Nut
                      </li>
                    )}
                  </ul>
                  <h2 className="text-2xl font-bold mb-4">
                    Activated Commodities
                  </h2>
                  <ul>
                    {activatedCommodities.length > 0 ? (
                      activatedCommodities.map((commodity, index) => (
                        <li key={index} className="text-gray-700">
                          {commodity}
                        </li>
                      ))
                    ) : (
                      <p className="text-gray-500">None</p>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommoditySettings;
