import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  IoCaretDownCircleOutline,
  IoCaretUpCircleOutline,
} from "react-icons/io5"; // Import icons

const History = () => {
  const sleekId = localStorage.getItem("sleekId"); // Retrieve sleekId from localStorage
  const [historyItems, setHistoryItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const historyListRef = useRef(null); // Ref for the history list container

  useEffect(() => {
    // Add 'overflow-hidden' to html and body on mount
    document.documentElement.classList.add("overflow-hidden");
    document.body.classList.add("overflow-hidden");

    // Clean up the overflow-hidden when component unmounts
    return () => {
      document.documentElement.classList.remove("overflow-hidden");
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  useEffect(() => {
    if (!sleekId) {
      setError("Sleek ID is missing. Please log in again.");
      return;
    }

    const fetchHistoryItems = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setError("No token found. Please log in.");
        return;
      }

      try {
        // Fetch history data using axios
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/history/latest-updates/${sleekId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add token for authentication
            },
          }
        );

        if (response.status === 200) {
          setHistoryItems(response.data); // Set history items
        } else if (response.status === 204) {
          setHistoryItems([]); // No content, set empty history
        } else {
          throw new Error("Failed to fetch history data.");
        }
      } catch (error) {
        console.error("Error fetching history items:", error);
        setError("Failed to load history items. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchHistoryItems();
  }, [sleekId]);

  useEffect(() => {
    // Filter history items based on the 'attribute' field instead of 'details'
    const filtered = historyItems.filter((item) =>
      item.attribute?.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [filterText, historyItems]);

  const scrollUp = () => {
    if (historyListRef.current) {
      historyListRef.current.scrollTop -= 50; // Scroll up by 50 pixels
    }
  };

  const scrollDown = () => {
    if (historyListRef.current) {
      historyListRef.current.scrollTop += 50; // Scroll down by 50 pixels
    }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item); // Set the clicked item as the selected item
  };

  // Helper function to format fields like PROFILE_PICTURE to "Profile Picture"
  const formatField = (field) => {
    return field
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* Main Content Area */}
      <div className="flex-1 p-8 ml-40" style={{ maxWidth: "70%" }}>
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-2xl font-bold text-gray-700">
            Change Audit History
          </h2>
        </div>

        <div className="flex items-center space-x-2 mb-4">
          <button
            onClick={scrollDown}
            className="bg-white border border-gray-400 text-gray-600 p-2 rounded shadow-sm flex items-center justify-center hover:bg-gray-50 transition-transform transform active:translate-y-1 active:shadow-inner"
            style={{ width: "45px", height: "45px" }}
          >
            <IoCaretDownCircleOutline style={{ fontSize: "32px" }} />
          </button>
          <button
            onClick={scrollUp}
            className="bg-white border border-gray-400 text-gray-600 p-2 rounded shadow-sm flex items-center justify-center hover:bg-gray-50 transition-transform transform active:translate-y-1 active:shadow-inner"
            style={{ width: "45px", height: "45px" }}
          >
            <IoCaretUpCircleOutline style={{ fontSize: "32px" }} />
          </button>
          {/* Change placeholder to Filter History */}
          <input
            type="text"
            placeholder="Filter History"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="p-2 border flex-grow rounded shadow-sm"
          />
        </div>

        {loading ? (
          <p className="text-gray-600">Loading history...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : filteredItems.length === 0 ? (
          <p className="text-gray-600">No history items available.</p>
        ) : (
          <div
            className="space-y-4 overflow-y-auto"
            ref={historyListRef} // Apply ref to this container
            style={{ maxHeight: "400px", scrollbarWidth: "thin" }} // Set fixed height for scrollable area and reduce scrollbar width
          >
            {filteredItems.map((item, index) => (
              <div
                key={index}
                className="p-4 bg-white rounded-lg shadow-md border border-gray-200 cursor-pointer hover:shadow-lg transition-shadow"
                onClick={() => handleItemClick(item)}
              >
                {/* 1) Format timestamp with I.P. Address */}
                <p className="text-sm text-gray-500">
                  {new Date(item.timestamp).toLocaleString()} from I.P. Address:{" "}
                  {item.ipAddress}
                </p>

                {/* 2) Make details text bold */}
                <p className="font-semibold text-gray-700 mt-2">
                  {item.details}
                </p>

                {/* 3) Format section and attribute, capitalize first letter and make pipes bold */}
                <p className="text-sm text-gray-500 mt-1">
                  {item.sleek.charAt(0).toUpperCase() +
                    item.sleek.slice(1).toLowerCase()}{" "}
                  <span className="font-bold"> | </span> {/* Bold pipe */}
                  {item.section ? formatField(item.section) : "Settings"}{" "}
                  <span className="font-bold"> | </span> {/* Bold pipe */}
                  {item.attribute ? formatField(item.attribute) : "Email"}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Right Sidebar */}
      <div className="fixed right-0 bg-gray-50 w-[15%] h-full p-4 text-sm leading-tight shadow-inner">
        <h3 className="font-bold text-gray-700 mb-2">Supported Commodities</h3>
        <p className="text-gray-600">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
        </p>
      </div>
    </div>
  );
};

export default History;
