import axios from "axios";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import loginBackground from "./banner.png";
import krissaco from "./krissaco-logo.png";
import sleek from "./sleek.png";
import one from "./one.png";
import two from "./two.png";
import three from "./three.png";
import fourone from "./fourone.png";
import fourtwo from "./fourtwo.png";
import fourthree from "./fourthree.png";

const LoginPage = () => {
  // State for form data (subscriptionId and password)
  const [formData, setFormData] = useState({
    subscriptionId: "",
    password: "",
  });

  // State for error message
  const [errorMessage, setErrorMessage] = useState("");

  // State to track if the login process is loading
  const [isLoading, setIsLoading] = useState(false);

  // State for toggling password visibility
  const [showPassword, setShowPassword] = useState(false);

  // React Router navigation hook
  const navigate = useNavigate();

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission for login
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when the request starts

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscriptions/${formData.subscriptionId}/login`,
        {
          password: formData.password,
        }
      );

      // Check if the login was successful (status 200)
      if (response.status === 200) {
        // Store token and subscription ID in localStorage on successful login
        localStorage.setItem("token", response.data); // Store JWT token
        localStorage.setItem("sleekId", formData.subscriptionId); // Store Sleek ID
        navigate("/workspace"); // Redirect to workspace after successful login
      }
    } catch (error) {
      // Check if the error is related to the backend response
      if (error.response) {
        // Handle specific case for unactivated account
        if (
          error.response.status === 401 &&
          error.response.data ===
          "Your account is not activated. Please check your email for the activation link."
        ) {
          setErrorMessage(
            "Your account is not activated. Please check your email for the activation link."
          );
        }
        // Handle invalid credentials
        else if (error.response.status === 401) {
          setErrorMessage("Invalid Subscription ID or Password.");
        }
        // Handle other backend errors
        else {
          setErrorMessage("Login failed. Please try again.");
        }
      } else {
        // General error for failed request (network or server issues)
        setErrorMessage("Login failed. Please try again.");
      }
    } finally {
      // Set loading to false after the request completes (success or failure)
      setIsLoading(false);
    }
  };

  // Redirect to registration page
  const handleRegisterRedirect = () => {
    navigate("/register");
  };

  return (
    <div>
      <div className="relative">
        <img src={krissaco} className="absolute t-10 l-10 w-64 h-auto"></img>
        <img src={loginBackground} className="w-full h-screen object-cover"></img>
        <div className="absolute inset-0 flex items-center justify-begin">
          <div className="flex flex-col items-center justify-center min-h-screen ml-32">
            <img src={sleek} className="w-64 h-auto mb-4"></img>
            <div className="text-center text-3xl text-white italic">Streamline your food processing, the Sleek way!</div>
          </div>
        </div>
        <div className="absolute inset-0 flex items-center justify-end">
          <div className="bg-white bg-opacity-90 p-6 shadow-lg rounded-lg max-w-lg w-full mr-10">
            <h1 className="text-2xl sm:text-3xl font-bold mb-4 font-sans text-center">
              Login to Sleek
            </h1>
            <p className="text-lg sm:text-xl mb-4 font-sans text-center">
              See how your processing plant is performing
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="text-lg font-sans mb-2 block">
                  Enter your unique Sleek ID{" "}
                  <span className="text-red-500">(required)</span>
                </label>
                <input
                  type="text"
                  name="subscriptionId"
                  value={formData.subscriptionId}
                  onChange={handleChange}
                  className="border border-gray-400 p-2 w-full rounded-md"
                  placeholder="http://www.krissaco.com/"
                  required
                />
                <p className="text-sm text-gray-600 mt-1 font-sans">
                  Do not have a Sleek ID?{" "}
                  <span
                    className="text-blue-500 cursor-pointer underline"
                    onClick={handleRegisterRedirect}
                  >
                    Register!
                  </span>
                </p>
              </div>
              <div className="mb-4">
                <label className="text-lg font-sans mb-2 block">
                  Enter password <span className="text-red-500">(required)</span>
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="border border-gray-400 p-2 w-full rounded-md"
                    placeholder="Enter your password"
                    required
                  />
                  {/* Eye Icon for password visibility */}
                  <span
                    className="absolute right-3 top-3 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
              <div>
                Forgot password? Click{" "}
                <span className="text-blue-500 cursor-pointer underline">here</span>{" "}
                and check your registered email for the password.
              </div>

              <div className="flex justify-between mt-4">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded-md"
                  disabled={isLoading} // Disable button when loading
                >
                  {isLoading ? "Logging in..." : "Login"} {/* Show loading state */}
                </button>
                <button
                  type="button"
                  className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md"
                  onClick={() => setFormData({ subscriptionId: "", password: "" })}
                >
                  Clear
                </button>
              </div>
            </form>
            {errorMessage && (
              <p className="text-red-500 mt-4 font-sans">{errorMessage}</p>
            )}
          </div>
        </div>
      </div>
      <div class="flex p-10 border-l-[32px] border-red-500">
        <div class="flex-1 p-4">
          <div className="flex flex-col items-center justify-center m-8">
            <img src={one} className="w-64 h-auto mb-4"></img>
            <div className="">
              <div className="text-center text-2xl">Data Inaccuracy and Inefficiency</div>
              <p className="text-justify">Manual data entry leads to typos, missing info, and duplication. These errors slow operations like inventory tracking, causing inefficiencies and harming profitability.</p>
            </div>
          </div>
        </div>
        <div class="flex-1 p-4">
          <div className="flex flex-col items-center justify-center m-8">
            <img src={two} className="w-64 h-auto mb-4"></img>
            <div className="">
              <div className="text-center text-2xl">Lack of Up-to-Date Data and Predictive Insights</div>
              <p className="text-justify">Outdated systems limit access to current data, causing delays in decision-making. Missed opportunities arise from outdated sales or inventory information.</p>
            </div>
          </div>
        </div>

        <div class="flex-1 p-4">
          <div className="flex flex-col items-center justify-center m-8">
            <img src={three} className="w-64 h-auto mb-4"></img>
            <div className="">
              <div className="text-center text-2xl">Challenges in Data Consolidation and Reporting</div>
              <p className="text-justify">Different systems across departments make data integration hard, leading to incomplete reports, and affecting management's ability to track performance or ensure compliance.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="p-10 border-r-[32px] border-red-500">
        <div className="flex items-center justify-center m-8">
          <img src={fourone} className="flex-1 w-64 h-auto mb-4"></img>
          <div className="flex-2">
            <div className="text-center text-2xl">Automatic Data Entry and Instant Access</div>
            <p className="text-justify">Sleek automates data entry, eliminating manual input and reducing human error. This keeps your data accurate and accessible.
              With data captured instantly, everyone on your team can access the information without delays. This immediate availability keeps everyone aligned, facilitating quicker decision-making. You’ll no longer waste time waiting for updates or hunting down data from various sources, allowing your team to concentrate on strategic initiatives and operational efficiency.</p>
          </div>
        </div>
        <div className="flex items-center justify-center m-8">
          <div className="flex-2">
            <div className="text-center text-2xl">All Data in One Place with Live Performance Tracking</div>
            <p className="text-justify">Sleek centralizes all business data into a single dashboard, saving you time and effort. No more searching through various platforms.
              With everything organized in one place, you can easily access the information you need. Sleek also provides real-time tracking of key performance indicators (KPIs), giving you a clear, up-to-date view of your business's performance. This allows you to quickly identify trends, spot potential problems early, and make informed, data-driven decisions that optimize your operations and enhance overall productivity.</p>
          </div>
          <img src={fourtwo} className="flex-1 w-64 h-auto mb-4 scale-x-[-1]"></img>
        </div>
        <div className="flex items-center justify-center m-8">
          <img src={fourthree} className="flex-1 w-64 h-auto mb-4"></img>
          <div className="flex-2">
            <div className="text-center text-2xl">Automatic Reports with Built-In Compliance</div>
            <p className="text-justify">Sleek automates data entry, eliminating manual input and reducing human error. This keeps your data accurate and accessible.
              Sleek automatically generates detailed reports, reducing the workload for manual reporting. Compliance is met without additional effort.
              These reports are designed to adhere to industry standards and regulatory requirements, which is especially useful for businesses facing audits or needing to submit regular reports. By automating the reporting process, you can focus on running your business without the stress of paperwork. The reports are always accurate, timely, and ready for review whenever needed, allowing you to make informed decisions without any hassle.</p>          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
