import React from "react";
// import profileIcon from "../images/profileIcon.png"; // Import your icon image
// import cashewIcon from "../images/cashewIcon.png";
// import processingIcon from "../images/processingIcon.png";
import gallery from "./gallery.jpg";
import add from "./add.jpg";
import cashew from "./cashew.jpg";
import { useNavigate } from "react-router-dom";
const HomeWorkspace = () => {
  const navigate = useNavigate();
  return (
    <div className="p-8 mx-20 my-20">
      <h1 className="text-3xl font-bold mb-4">
        What do you want to do with Krissaco Sleek today?
      </h1>
      <p className="mb-8 text-gray-700">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor
        sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
        ut labore et dolore magna aliqua.
      </p>

      {/* First Section */}
      <div className="flex items-center mb-8">
        <img src={gallery} alt="Profile Icon" className="w-16 h-16 mr-4" />
        <div>
          <h2 className="text-xl font-semibold mb-2">
            You can change your profile
          </h2>
          <p className="text-gray-600 mb-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <button
            className="bg-gray-600 text-white px-4 py-2 rounded"
            onClick={() => navigate("/workspace/settings/profile")}
          >
            Go to Profile settings
          </button>
        </div>
      </div>

      {/* Second Section */}
      <div className="flex items-center mb-8">
        <img
          src={add}
          alt="Cashew Icon"
          className="w-16 h-16 mr-4 rounded-xl"
        />
        <div>
          <h2 className="text-xl font-semibold mb-2">
            You can activate Cashew Nut Processing
          </h2>
          <p className="text-gray-600 mb-2">
            Pd tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.
          </p>
          <button
            className="bg-gray-600 text-white px-4 py-2 rounded"
            onClick={() => navigate("/workspace/settings/commodities")}
          >
            Go to Commodities
          </button>
        </div>
      </div>

      {/* Third Section */}
      <div className="flex items-center mb-8">
        <img
          src={cashew}
          alt="Processing Icon"
          className="w-16 h-16s mr-4 rounded"
        />
        <div>
          <h2 className="text-xl font-semibold mb-2">
            Analyse your Processing Efficiencies
          </h2>
          <p className="text-gray-600 mb-2">
            Adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </p>
          <button
            className="bg-gray-600 text-white px-4 py-2 rounded"
            onClick={() => navigate("cashew/dashboard")}
          >
            Go to Cashew Nut Processing
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeWorkspace;
