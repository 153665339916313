import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { token, email } = useParams(); // Extract token and email from the route parameters
  const navigate = useNavigate(); // Initialize navigate hook

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(
        //`http://localhost:8080/subscriptions/reset-password`,
        `http://localhost:8080/subscriptions/reset-password`,
        { passwordResetToken: token, newPassword: newPassword }
      );
      console.log(response.data);
      setMessage("Password reset successfully! Redirecting to login...");

      // Redirect to login page after a short delay
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      console.error("Error Response:", error.response); // Detailed log
      setErrorMessage(
        error.response?.data?.message ||
          "Error resetting password. Please try again."
      );
    }
  };
  console.log("Token ", token);
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-6 shadow-lg rounded-lg max-w-lg w-full">
        <h1 className="text-2xl font-bold mb-4">Reset Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-lg mb-2 block">New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="border border-gray-400 p-2 w-full rounded-md"
              placeholder="Enter new password"
              required
            />
          </div>
          <div className="mb-4">
            <label className="text-lg mb-2 block">Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="border border-gray-400 p-2 w-full rounded-md"
              placeholder="Confirm your password"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
          >
            Reset Password
          </button>
        </form>
        {message && <p className="text-green-500 mt-4">{message}</p>}
        {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
