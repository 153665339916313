import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExportIcon from "../images/Export button.png";
import MailIcon from "../images/Mail icon.png";
import PdfIcon from "../images/PDF icon.png";
import BarGraph from "./BarGraph";

const stages = [
  { value: "all", label: "All Stages" },
  { value: "overallEfficiency", label: "Overall Efficiency" },
  { value: "boilingSteamingInput", label: "Boiling/Steaming" },
  { value: "gradingInput", label: "Grading" },
  { value: "primaryShellingInput", label: "Primary Shelling" },
  { value: "secondaryShellingInput", label: "Secondary Shelling" },
  { value: "chillingInput", label: "Chilling" },
  { value: "bormaDryingInput", label: "Borma Drying" },
  { value: "peelingInput", label: "Peeling" },
  { value: "sweatingInput", label: "Sweating" },
  { value: "sortingInput", label: "Sorting" },
  { value: "packagingInput", label: "Packaging" },
];
const CashewNutDashboard = () => {
  const [processData, setProcessData] = useState({});
  const [stagesData, setStagesData] = useState({});
  const [selectedDate, setSelectedDate] = useState(""); // Keeps track of the selected date
  const [viewType, setViewType] = useState("Charts");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFetched, setIsFetched] = useState(false);
  const navigate = useNavigate();

  const defaultStagesData = {
    boilingSteaming: 0,
    grading: 0,
    cutting: 0,
    primaryShelling: 0,
    secondaryShelling: 0,
    bormaDrying: 0,
    cooling: 0,
    peeling: 0,
    sweating: 0,
    sorting: 0,
    packaging: 0,
  };

  // Retrieve JWT token and sleekId from localStorage for authenticated requests
  const getToken = () => localStorage.getItem("token");
  const getSleekId = () => localStorage.getItem("sleekId");
  const getEmail = () => localStorage.getItem("email");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const defaultProcessData = {
    boilingSteamingInput: 0,
    packagingOutput: 0,
    boilingSteamingOutput: 0,
  };

  // Helper function to format date to YYYY-MM-DD
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Ensure it’s in YYYY-MM-DD format
  };

  useEffect(() => {
    // Fetch the latest available date with data
    const fetchLatestDateWithData = async () => {
      const sleekId = localStorage.getItem("sleekId");
      const token = localStorage.getItem("token");

      if (!sleekId || !token) {
        setError("Sleek ID or token not found. Please log in again.");
        return;
      }

      try {
        // Call the new endpoint for the latest available date
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/${sleekId}/processing/latest-available-date`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch the latest available date.");
        }

        const latestAvailableDate = await response.json();
        setSelectedDate(formatDate(latestAvailableDate)); // Format the date and set it
        setIsFetched(true); // Trigger data fetching after getting the latest available date
      } catch (error) {
        console.error("Error fetching latest available date:", error.message);
        setError("Failed to fetch the latest available date.");
      }
    };

    fetchLatestDateWithData();
  }, []);

  useEffect(() => {
    if (selectedDate && isFetched) {
      const fetchData = async () => {
        const sleekId = localStorage.getItem("sleekId");
        const token = localStorage.getItem("token");

        if (!sleekId || !token) {
          setError("Sleek ID or token not found. Please log in again.");
          return;
        }

        setLoading(true);

        try {
          const processingResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/${sleekId}/processing?date=${selectedDate}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const stagesResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/${sleekId}/commodities/process-stages`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!processingResponse.ok || !stagesResponse.ok) {
            const errorMessage = `Processing API status: ${
              processingResponse.status || "Unknown"
            }, Stages API status: ${stagesResponse.status || "Unknown"}`;
            throw new Error(errorMessage);
          }

          const processingData = await processingResponse.json();
          const stagesData = await stagesResponse.json();

          setProcessData(processingData.length > 0 ? processingData[0] : {});
          setStagesData(stagesData);
          setError(null);
        } catch (error) {
          console.error("Error fetching data:", error.message);
          setError(`Failed to fetch data: ${error.message}`);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedDate, isFetched]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setIsFetched(false); // Reset fetch state when date changes
  };

  const handleViewChange = (event) => {
    setViewType(event.target.value);
  };

  const handleFetchAndView = () => {
    if (selectedDate) {
      setIsFetched(true); // Set to true only when Go is clicked
    }
  };

  const handleExportPDF = useCallback(() => {
    const doc = new jsPDF();
  
    // Add title to the PDF
    const title = "Cashew Nut Processing Insights";
    doc.setFontSize(28);
    const pageWidth = doc.internal.pageSize.getWidth();
    const titleWidth = doc.getTextWidth(title);
    doc.text(title, (pageWidth - titleWidth) / 2, 20);
  
    // Add subtitle to the PDF
    const subtitle = `Efficiency Data for ${selectedDate}`;
    doc.setFontSize(14);
    const subtitleWidth = doc.getTextWidth(subtitle);
    doc.text(subtitle, (pageWidth - subtitleWidth) / 2, 30);
  
    // Table columns
    const tableColumn = ["Stage", "Input", "Output", "Efficiency (%)", "Loss (%)"];
  
    // Table rows for the data
    const tableRows = [];
  
    // Overall efficiency
    const overallInput = processData?.boilingSteamingInput || 0;
    const overallOutput = processData?.packagingOutput || 0;
    const overallEfficiency = (overallOutput / overallInput) * 100;
    const overallLossPercent = ((overallInput - overallOutput) / overallInput) * 100;
  
    // Push the overall efficiency data to table rows
    tableRows.push([
      "Overall Efficiency",
      overallInput.toFixed(2),
      overallOutput.toFixed(2),
      overallEfficiency.toFixed(2) + "%",
      overallLossPercent.toFixed(2) + "%",
    ]);
  
    // Loop through each stage and add the data to tableRows
    stages.forEach((stage) => {
      if (stage.value !== "overallEfficiency" && stage.value !== "all") {
        const input = processData?.[`${stage.value}Input`] || 0;
        const output = processData?.[`${stage.value}Output`] || 0;
        const efficiency = (output / input) * 100;
        const lossPercent = ((input - output) / input) * 100;
  
        tableRows.push([
          stage.label,
          input.toFixed(2),
          output.toFixed(2),
          efficiency.toFixed(2) + "%",
          lossPercent.toFixed(2) + "%",
        ]);
      }
    });
  
    // Use autoTable to add the table to the PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 40, // Adjust starting Y position
      theme: "striped",
      margin: { top: 50 },
    });
  
    // Save the PDF
    doc.save("Cashew_Nut_Processing_Dashboard_Report.pdf");
  }, [processData, selectedDate, stages]);
  const handleSendEmail = async(e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const token = getToken();
      const sleekId = getSleekId(); // Use sleekId to identify the user

      if (!token || !sleekId) {
        setErrorMessage("Sleek ID or token not found. Please log in again.");
        return;
      }

      // Prepare the email content (body) with the dashboard data
      const generateEmailBody = () => {
        let emailBody = `Cashew Nut Processing Efficiency - ${selectedDate}\n\n`;

        emailBody += `Overall Efficiency: \n`;
        const overallEfficiencyData = calculateEfficiency(
          processData?.boilingSteamingInput ||
            defaultProcessData.boilingSteamingInput,
          processData?.packagingOutput || defaultProcessData.packagingOutput,
          Object.values(stagesData).reduce((acc, stage) => acc + stage, 0)
        );
        emailBody += `- Input: ${overallEfficiencyData.total.toFixed(2)} Kg\n`;
        emailBody += `- Realized Output: ${overallEfficiencyData.realized.toFixed(
          2
        )} Kg\n`;
        emailBody += `- Expected Output: ${overallEfficiencyData.expected.toFixed(
          2
        )} Kg\n`;
        emailBody += `- Efficiency: ${(
          (overallEfficiencyData.realized / overallEfficiencyData.total) *
          100
        ).toFixed(2)}%\n\n`;

        // Add stage-specific data
        Object.keys(defaultStagesData).forEach((stage) => {
          const stageData = calculateEfficiency(
            processData?.[`${stage}Input`] || 0,
            processData?.[`${stage}Output`] || 0,
            stagesData?.[stage] || 0
          );
          emailBody += `${stage.replace(/([A-Z])/g, " $1")}: \n`;
          emailBody += `- Input: ${stageData.total.toFixed(2)} Kg\n`;
          emailBody += `- Realized Output: ${stageData.realized.toFixed(
            2
          )} Kg\n`;
          emailBody += `- Expected Output: ${stageData.expected.toFixed(
            2
          )} Kg\n`;
          emailBody += `- Efficiency: ${(
            (stageData.realized / stageData.total) *
            100
          ).toFixed(2)}%\n\n`;
        });

        // Add major concern (stage with the lowest efficiency)
        const majorConcern = findMajorConcernStage();
        emailBody += `Major Concern: ${majorConcern}\n`;

        return emailBody;
      };

      const emailBody = generateEmailBody(); // Generate the email body

      // Make the API call to send the email
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/emails/send-mail?sleekId=${sleekId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass the token for authentication
          },
          body: JSON.stringify({
            subject: "Cashew Nut Processing Dashboard Insights", // Send the subject
            text: emailBody, // Send the email body (content)
          }),
        }
      );

      if (response.ok) {
        setSuccessMessage("Email sent successfully!");
        setErrorMessage(""); // Clear any previous error messages
      } else {
        const errorResponse = await response.json(); // Get error response
        setErrorMessage(
          `Failed to send email: ${
            errorResponse.message || "Please try again."
          }`
        );
        setSuccessMessage(""); // Clear any previous success messages
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred.");
      setSuccessMessage("");
    }
  };

  const handleExportData = () => {
    const csvRows = [];
  
    // Headers
    const headers = ["Stage", "Input"];
    csvRows.push(headers.join(","));
  
    // Add overall efficiency input data
    const overallInput = processData?.boilingSteamingInput || 0;
    csvRows.push(`Overall Efficiency,${overallInput}`);
  
    // Add stage-wise input data
    stages.forEach((stage) => {
      if (stage.value !== "overallEfficiency" && stage.value !== "all") {
        const input = processData?.[stage.value] || 0; // Access stage input directly
        csvRows.push(`${stage.label},${input}`);
      }
    });
  
    // Create a Blob from the CSV content
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
  
    // Create a link element and trigger a download
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "Input_Data in Dashboard section");
    a.click();
  };
  

  const calculateEfficiency = (input, output, lossThreshold) => {
    const safeInput = input || 0;
    const safeOutput = output || 0;
    const safeLossThreshold = lossThreshold || 0;

    const total = safeInput;
    const realized = safeOutput;
    const expected = total * (1 - safeLossThreshold / 100);

    return { total, realized, expected };
  };

  const findMajorConcernStage = () => {
    let lowestEfficiencyStage = "None";
    let lowestEfficiency = Infinity;

    Object.keys(defaultStagesData).forEach((stage) => {
      const { total, realized } = calculateEfficiency(
        processData?.[`${stage}Input`] || 0,
        processData?.[`${stage}Output`] || 0,
        stagesData?.[stage] || 0
      );
      const efficiency = total ? (realized / total) * 100 : 0;

      if (efficiency < lowestEfficiency) {
        lowestEfficiency = efficiency;
        lowestEfficiencyStage = stage.replace(/([A-Z])/g, " $1");
      }
    });

    return lowestEfficiencyStage;
  };

  const majorConcern = findMajorConcernStage();

  const renderEfficiencyData = (stage, input, output, lossThreshold) => {
    const { total, realized, expected } = calculateEfficiency(
      input,
      output,
      lossThreshold
    );
    const loss = total - realized;
    const lossPercent = total ? (loss / total) * 100 : 0;
    const efficiency = total ? (realized / total) * 100 : 0;
    const expectedPercent = total ? (expected / total) * 100 : 0;

    return (
      <div key={stage} className="mt-4 p-4 border rounded shadow-md">
        <h3 className="text-lg font-bold">
          {stage.replace(/([A-Z])/g, " $1")} - {efficiency.toFixed(2)}%
        </h3>
        <p>Input: {total.toFixed(2)} Kg</p>
        <p>Expected Output: {expected.toFixed(2)} Kg</p>
        <p>Realized Output: {realized.toFixed(2)} Kg</p>
        <p>
          Loss: {loss.toFixed(2)} Kg ({Math.abs(realized - expected).toFixed(2)}{" "}
          Kg {realized > expected ? "better" : "less"} than{" "}
          {expected.toFixed(2)} Kg normal)
        </p>
        <p>
          Loss (%): {lossPercent.toFixed(2)} % (
          {Math.abs(efficiency - expectedPercent).toFixed(2)} %{" "}
          {efficiency > expectedPercent ? "better" : "less"} than{" "}
          {expectedPercent.toFixed(2)} % normal)
        </p>
        <p>
          Efficiency: {efficiency.toFixed(2)} % (
          {Math.abs(efficiency - expectedPercent).toFixed(2)} %{" "}
          {efficiency > expectedPercent ? "better" : "less"} than{" "}
          {expectedPercent.toFixed(2)} % normal)
        </p>
        {/* Display Major Concern here */}
        {stage === "Overall Efficiency" && <p>Major Concern: {majorConcern}</p>}
      </div>
    );
  };

  const overallEfficiency = calculateEfficiency(
    processData?.boilingSteamingInput ||
      defaultProcessData.boilingSteamingInput,
    processData?.packagingOutput || defaultProcessData.packagingOutput,
    Object.values(stagesData).reduce((acc, stage) => acc + stage, 0)
  );

  return (
    <div className="flex ml-36 h-screen">
      <div className="flex-1 p-8" style={{ maxWidth: "70%" }}>
        <div className="flex justify-between items-start mb-4">
          <div>
            <h1 className="text-2xl font-bold">
              Cashew Nut Processing Efficiency
            </h1>
            <p>
              View the performance of different stages in cashew nut processing,
              including total input, expected output, and realized output.
            </p>
          </div>
        </div>

        <div className="mb-4 flex items-center space-x-2">
          <input
            type="date"
            value={selectedDate} // Show the latest fetched date here
            onChange={handleDateChange}
            max={new Date().toISOString().split("T")[0]}
            className="bg-white p-2 rounded border-2 border-black text-black"
          />
          <div className="flex space-x-2">
            <label className="flex items-center">
              <input
                type="radio"
                name="view"
                value="Charts"
                className="mr-2"
                checked={viewType === "Charts"}
                onChange={handleViewChange}
              />
              Charts
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="view"
                value="Numbers"
                className="mr-2"
                checked={viewType === "Numbers"}
                onChange={handleViewChange}
              />
              Numbers
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="view"
                value="Both"
                className="mr-2"
                checked={viewType === "Both"}
                onChange={handleViewChange}
              />
              Both
            </label>
          </div>
          <button
            className="ml-4 bg-gray-800 p-2 rounded hover:bg-gray-700 text-white"
            onClick={handleFetchAndView}
          >
            Go
          </button>
        </div>

        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}

        {!loading && !error && isFetched && viewType === "Charts" && (
          <>
            <BarGraph title="Overall Efficiency" {...overallEfficiency} />
            {Object.keys(defaultStagesData).map((stage) => (
              <BarGraph
                key={stage}
                title={stage.replace(/([A-Z])/g, " $1")}
                {...calculateEfficiency(
                  processData?.[`${stage}Input`] || 0,
                  processData?.[`${stage}Output`] || 0,
                  stagesData?.[stage] || 0
                )}
              />
            ))}
          </>
        )}

        {!loading && !error && isFetched && viewType === "Numbers" && (
          <div id="dashboard-content">
            {renderEfficiencyData(
              "Overall Efficiency",
              processData?.boilingSteamingInput ||
                defaultProcessData.boilingSteamingInput,
              processData?.packagingOutput ||
                defaultProcessData.packagingOutput,
              Object.values(stagesData).reduce((acc, stage) => acc + stage, 0)
            )}
            {Object.keys(defaultStagesData).map((stage) =>
              renderEfficiencyData(
                stage,
                processData?.[`${stage}Input`] || 0,
                processData?.[`${stage}Output`] || 0,
                stagesData?.[stage] || 0
              )
            )}
          </div>
        )}

        {!loading && !error && isFetched && viewType === "Both" && (
          <>
            <BarGraph title="Overall Efficiency" {...overallEfficiency} />
            {renderEfficiencyData(
              "Overall Efficiency",
              processData?.boilingSteamingInput ||
                defaultProcessData.boilingSteamingInput,
              processData?.packagingOutput ||
                defaultProcessData.packagingOutput,
              Object.values(stagesData).reduce((acc, stage) => acc + stage, 0)
            )}
            {Object.keys(defaultStagesData).map((stage) => (
              <React.Fragment key={stage}>
                <BarGraph
                  title={stage.replace(/([A-Z])/g, " $1")}
                  {...calculateEfficiency(
                    processData?.[`${stage}Input`] || 0,
                    processData?.[`${stage}Output`] || 0,
                    stagesData?.[stage] || 0
                  )}
                />
                {renderEfficiencyData(
                  stage,
                  processData?.[`${stage}Input`] || 0,
                  processData?.[`${stage}Output`] || 0,
                  stagesData?.[stage] || 0
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </div>

      <div className="flex flex-col items-center justify-start p-4 space-y-4">
        <img
          src={PdfIcon}
          alt="PDF Export"
          className="w-14 h-14 cursor-pointer"
          onClick={handleExportPDF}
        />
        <img
          src={MailIcon}
          alt="Send Email"
          className="w-14 h-14 cursor-pointer"
          onClick={handleSendEmail}
        />
        <img
          src={ExportIcon}
          alt="Export Data"
          className="w-14 h-14 cursor-pointer"
          onClick={handleExportData}
        />
      </div>

      <div className="fixed right-0 bg-gray-50 w-[15%] h-full p-2 text-sm leading-tight">
        <h2 className="text-xl font-bold mb-4">Dashboard</h2>
        <p>1. The default dashboard value is available at the latest date.</p>
        <p>
          2. The values can be viewed in charts, numbers, or both as per user
          preference.
        </p>
        <p>3. Green indicates the expected value.</p>
        <p>4. Red indicates the realized loss.</p>
        <p>5. Grey indicates the total input.</p>
        <p>
          6. Clicking the PDF icon allows you to download the dashboard as a
          PDF.
        </p>
        <p>7. Clicking the Mail icon allows you to email the dashboard PDF.</p>
      </div>
    </div>
  );
};

export default CashewNutDashboard;
