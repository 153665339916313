import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for password visibility toggle
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  // State variables to manage form inputs, messages, and API data
  const [workspaceName, setWorkspaceName] = useState(""); // Organization name
  const [subscriberEmail, setSubscriberEmail] = useState(""); // Email input
  const [password, setPassword] = useState(""); // Password input
  const [confirmPassword, setConfirmPassword] = useState(""); // Confirm password input
  const [profileImage, setProfileImage] = useState(null); // Profile image input
  const [message, setMessage] = useState(""); // Success or error message
  const [messageColor, setMessageColor] = useState(""); // Color for the message (success/error)
  const [imageError, setImageError] = useState(""); // Error message for invalid image format
  const [passwordError, setPasswordError] = useState(""); // Error message for invalid password
  const [confirmPasswordError, setConfirmPasswordError] = useState(""); // Password match error
  const [emailError, setEmailError] = useState(""); // Error message for invalid email
  const [showPassword, setShowPassword] = useState(false); // Toggle for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle for confirm password visibility
  const [originalWorkspaceName, setOriginalWorkspaceName] = useState(""); // Original workspace name from the API
  const [originalEmail, setOriginalEmail] = useState(""); // Original email from the API
  const [pendingEmail, setPendingEmail] = useState(""); // Pending email awaiting activation
  const [isLoading, setIsLoading] = useState(false); // Loading state during form submission

  const navigate = useNavigate(); // Navigation function from react-router-dom
  const sleekId = localStorage.getItem("sleekId"); // Subscription ID from localStorage
  const token = localStorage.getItem("token"); // JWT token from localStorage

  // Function to fetch subscription details on component mount
  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      if (!token || !sleekId) {
        navigate("/login"); // Redirect to login if no token or sleekId is found
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/subscriptions/${sleekId}/details`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Send the token for authorization
            },
            withCredentials: true, // Send cookies with the request
          }
        );

        if (response.status === 200) {
          const { workspaceName, email, pendingEmail } = response.data;

          // Store the values in state
          setOriginalWorkspaceName(workspaceName);
          setOriginalEmail(email);

          // If pending email exists, set it
          if (pendingEmail) {
            setPendingEmail(pendingEmail);
          } else {
            setPendingEmail("");
          }
        } else {
          navigate("/login"); // Redirect to login if the request fails
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("sleekId");
          navigate("/login"); // Redirect to login if unauthorized
        }
      }
    };

    fetchSubscriptionDetails(); // Fetch subscription details on component mount
  }, [sleekId, token, navigate]);

  // Handle profile image selection and validate its format
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    const validImageTypes = ["image/jpeg", "image/png"]; // Only allow JPEG/PNG

    if (file && validImageTypes.includes(file.type)) {
      setProfileImage(file); // Set the valid image file
      setImageError(""); // Clear previous image errors
    } else {
      setProfileImage(null); // Reset the image if invalid
      setImageError("Only .jpg and .png images are allowed."); // Set error message
    }
  };

  // Handle email input change and validation
  const handleEmailChange = (e) => {
    const email = e.target.value.trimStart(); // Trim leading whitespace
    const emailPattern =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|net|org|edu)$/; // Basic email format validation

    if (email === originalEmail) {
      setEmailError("New email cannot be the same as the current email."); // Prevent setting the same email
    } else {
      setSubscriberEmail(email); // Set the new email
      if (email && !emailPattern.test(email)) {
        setEmailError("Please enter a valid email (e.g., .com, .in, etc.)"); // Invalid email format
      } else {
        setEmailError(""); // Clear error if email is valid
      }
    }
  };

  // Handle workspace name input change
  const handleWorkspaceNameChange = (e) => {
    const workspace = e.target.value.trimStart(); // Trim leading whitespace
    setWorkspaceName(workspace); // Set the workspace name
  };

  // Handle password input change and validate its format
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value.trimStart(); // Trim leading whitespace
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$&])[A-Za-z\d@#$&]{10,}$/; // Password format: letters, numbers, and special characters

    setPassword(newPassword); // Set the new password
    if (newPassword && !passwordRegex.test(newPassword)) {
      setPasswordError(
        "Password must be 10 characters long and include letters, numbers, and at least one special character (&, @, #, $)"
      ); // Set password format error
    } else {
      setPasswordError(""); // Clear password error
    }
  };

  // Handle confirm password input change and check if it matches the password
  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value.trimStart(); // Trim leading whitespace
    setConfirmPassword(confirmPasswordValue); // Set confirm password

    if (password && confirmPasswordValue !== password) {
      setConfirmPasswordError("Passwords do not match"); // Password mismatch error
    } else {
      setConfirmPasswordError("Passwords match"); // Success message when passwords match
    }
  };

  // Toggle password visibility for the "New Password" field
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the visibility of password
  };

  // Toggle password visibility for the "Confirm Password" field
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle visibility of confirm password
  };

  // Handle form submission and update subscription details
  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent default form behavior
    setIsLoading(true); // Set loading state during submission

    // Ensure passwords match before submitting
    if (password && password !== confirmPassword) {
      setMessage("Passwords do not match!"); // Set error if passwords don't match
      setMessageColor("text-red-500"); // Display error in red
      setIsLoading(false); // Stop loading
      return;
    }

    // Ensure no validation errors exist before submitting
    if (imageError || passwordError || emailError) {
      setMessage("Please fix the errors before submitting.");
      setMessageColor("text-red-500"); // Display validation error in red
      setIsLoading(false); // Stop loading
      return;
    }

    // Prepare form data for submission
    const formData = new FormData();
    formData.append("workspaceName", workspaceName);
    formData.append("email", subscriberEmail); // Add email to form data
    if (password) formData.append("password", password); // Add password if provided
    if (profileImage) formData.append("profileImage", profileImage); // Add profile image if provided

    try {
      // Send PUT request to update subscription data
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/subscriptions/${sleekId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach JWT token for authorization
          },
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );

      if (response.status === 200) {
        setMessage("Subscription updated successfully!"); // General success message
        if (subscriberEmail && subscriberEmail !== originalEmail) {
          setMessage(
            (prevMessage) =>
              `${prevMessage} Please check your new email for an activation link.`
          ); // Additional message if email was updated
        }

        setMessageColor("text-green-500"); // Display success in green

        // Update original values after successful submission
        setOriginalWorkspaceName(workspaceName || originalWorkspaceName);
        if (subscriberEmail && subscriberEmail !== originalEmail) {
          setPendingEmail(subscriberEmail); // Set pending email for activation
        }
      } else {
        setMessage("Error updating subscription."); // Handle error response
        setMessageColor("text-red-500"); // Display error in red
      }
    } catch (error) {
      setMessage("Failed to update subscription."); // Handle submission errors
      setMessageColor("text-red-500"); // Display error in red
    } finally {
      setIsLoading(false); // Stop loading after request is complete
    }
  };

  return (
    <div className="flex justify-between">
      <div className="flex-1 bg-white p-6 rounded ml-36 max-w-[60%]">
        <h1 className="text-2xl font-bold mb-4">Change Profile Settings</h1>
        {message && <p className={`${messageColor} mb-4`}>{message}</p>}{" "}
        {/* Display success/error messages */}
        <form onSubmit={handleFormSubmit}>
          {/* Organization Name Input */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Do you want to change the name of your organization?
            </label>
            <input
              type="text"
              className="border border-black rounded w-full py-2 px-3 text-gray-700"
              placeholder="New Organization Name"
              maxLength={32}
              value={workspaceName}
              onChange={handleWorkspaceNameChange}
            />
          </div>

          {/* Email Input with pending email display */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Do you want to change your email?
            </label>
            <input
              type="email"
              className={`border rounded w-full py-2 px-3 text-gray-700 ${
                emailError ? "border-red-500" : "border-black" // Red border for invalid email, black otherwise
              }`}
              placeholder="New Email"
              value={subscriberEmail}
              onChange={handleEmailChange}
            />
            {emailError && <p className="text-red-500">{emailError}</p>}{" "}
            {/* Display email error */}
            {pendingEmail && (
              <p className="text-yellow-500 mt-2">
                Pending Email (Waiting for activation): {pendingEmail}
              </p>
            )}
          </div>

          {/* New Password Input */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Do you want to change Password?
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"} // Toggle password visibility
                className={`border rounded w-full py-2 px-3 text-gray-700 ${
                  passwordError ? "border-red-500" : "border-black" // Red border for invalid password, black otherwise
                }`}
                placeholder="New Password"
                value={password}
                onChange={handlePasswordChange}
                maxLength={16} // Limit password length
              />
              {/* Button to toggle password visibility */}
              <button
                type="button"
                className="absolute right-3 top-3"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Eye icon */}
              </button>
            </div>
            {passwordError && <p className="text-red-500">{passwordError}</p>}{" "}
            {/* Display password error */}
          </div>

          {/* Confirm Password Input */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Confirm password
            </label>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"} // Toggle confirm password visibility
                className="border border-black rounded w-full py-2 px-3 text-gray-700"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              {/* Button to toggle confirm password visibility */}
              <button
                type="button"
                className="absolute right-3 top-3"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                {/* Eye icon */}
              </button>
            </div>
            {confirmPasswordError && (
              <p
                className={`${
                  confirmPasswordError === "Passwords match"
                    ? "text-green-500"
                    : "text-red-500"
                }`} // Display success message in green or error in red
              >
                {confirmPasswordError}{" "}
                {/* Display confirm password error or success */}
              </p>
            )}
          </div>

          {/* Profile Image Upload */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Do you want to change your profile picture?
            </label>
            <input
              type="file"
              className="border border-black rounded w-full py-2 px-3"
              accept="image/jpeg,image/png" // Only allow JPEG/PNG images
              onChange={handleProfileImageChange} // Handle file input
            />
            {imageError && <p className="text-red-500">{imageError}</p>}{" "}
            {/* Display image error */}
          </div>

          {/* Submit and Clear Buttons */}
          <div className="flex">
            <button
              type="submit"
              className="bg-gray-600 text-white px-4 py-2 rounded mr-4"
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "Update Settings"}{" "}
              {/* Show "Updating..." while loading */}
            </button>
            <button
              type="reset"
              className="bg-gray-600 text-white px-4 py-2 rounded"
              onClick={() => {
                // Reset form fields and clear errors/messages
                setPassword("");
                setConfirmPassword("");
                setProfileImage(null);
                setImageError("");
                setPasswordError("");
                setConfirmPasswordError("");
                setMessage("");
                setEmailError("");
              }}
            >
              Clear {/* Clear form button */}
            </button>
          </div>
        </form>
      </div>

      {/* Sidebar displaying current user settings */}
      <div className="fixed right-0 bg-gray-50 w-[20%] h-full p-2 text-sm leading-tight">
        <h2 className="text-2xl font-bold mb-4">Your Current Settings</h2>
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Organization Name</h3>
          <p className="text-gray-700">{originalWorkspaceName}</p>{" "}
          {/* Display current workspace name */}
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold">Current Email</h3>
          <p className="text-gray-700">{originalEmail}</p>{" "}
          {/* Display current active email */}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
