import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload

    try {
      const response = await axios.post(
        "http://localhost:8080/subscriptions/forgot-password",
        { email }
      );

      console.log(response.data); // Debug log
      setMessage("Password reset link sent to your email!");
      setErrorMessage("");

      const { passwordResetToken } = response.data; // Extract reset token from backend response

      // Navigate to reset-password page with token and email as route parameters
      navigate(`/reset-password/${passwordResetToken}/${email}`);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Error sending password reset link. Please try again.");
      setMessage("");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-6 shadow-lg rounded-lg max-w-lg w-full">
        <h1 className="text-2xl font-bold mb-4">Forgot Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-lg mb-2 block">
              Enter your registered email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border border-gray-400 p-2 w-full rounded-md"
              placeholder="Enter your email"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
          >
            Send Reset Link
          </button>
        </form>
        {message && <p className="text-green-500 mt-4">{message}</p>}
        {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
